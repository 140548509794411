<template>
  <div class="portList">
    <!-- <div class="derive">
      <el-button size="small" @click="createPost()">新增接口</el-button>
    </div> -->
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" row-key="id" border>
        <el-table-column prop="route_name" label="接口名称"/>
        <el-table-column label="接口路径">
          <template slot-scope="scope">
            /{{scope.row.route_prefix}}/{{scope.row.route_path}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <port-modal ref="portModal"/>
  </div>
</template>

<script>
import portModal from './components/portModal.vue';
import {getRouteList} from "@/api/auth.js"
export default {
  components: { portModal },
  name: 'portList',

  data() {
    return {
      tableData:[]
    };
  },

  mounted() {
    this.getRouteList()
  },

  methods: {
    async getRouteList() {
      const {code,data} = await getRouteList()
      if(code == 200){
        this.tableData = data
      }
    },
    createPost(){
      this.$refs.portModal.isShow()
    },
    edit(row){
      this.$refs.portModal.isShow(row,"edit")
    }
  },
};
</script>

<style lang="scss" scoped>
.portList{
  .derive{
    margin-bottom: 10px;
  }  
  .auth-table{
    /deep/ .table_header{
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>